import { selectionTool } from './player-tools/select';
import { moveTool } from './player-tools/move';
import {
  internalAddItem,
  getItems,
  removeItems,
  getSeatingCapacity,
} from './modules/items';
import { frameScene } from './modules/camera';
import { getNamedConnectorForItem, attach } from './modules/placement';
import {
  setFloor,
  buildTKSceneConfiguration,
  setSceneConfiguration,
} from './modules/room';
import { updateCapacity } from './modules/capacity';
import { getSavedConfiguration } from './modules/persistence';
import { SELECTION_COLORS } from './modules/selection';

/* global __USE_PLAYER_CACHING__ */

const DEFAULT_FLOOR_TYPE = 'Espresso';

let initialConfigurationData; // reused on reset

export async function initPlayer(elId, authToken, assetId, initialSceneConfig) {
  const playerOpts = {
    authToken,
    el: document.getElementById(elId),
    assetId,
    initialConfiguration: initialSceneConfig,
    showConfigurator: false,
  };

  if (__USE_PLAYER_CACHING__ === 'true') {
    playerOpts.cache = {
      maxAge: 31536000,
      scope: 'v1.14',
    };
  } else {
    playerOpts.publishStage = 'draft';
  }

  const api = await window.threekitPlayer(playerOpts);

  api.enableApi('configurator');
  api.enableApi('player');
  api.enableApi('store');

  await api.when('loaded');

  // use custom-built selection and movement tools (other than the default
  // player's select/nodemove tools) to enable custom functionality
  api.tools.addTool(selectionTool);
  api.tools.addTool(moveTool);

  // turnTableMobileOnly is what causes the default orbit tool to scroll on
  // vertical swipe, instead of orbiting the camera vertically. In our case, we
  // don't want/need that behaviour, since our player is not fullscreen so the
  // user can still scroll by just swiping the non-player portion of the view
  // (ie BA's UI). So by setting turnTableMobileOnly to false, we retain the
  // ability for the user to orbit vertically, as requested by the client.
  api.tools.setTool('orbit', { options: { turnTableMobileOnly: false } });

  // selection outline color
  api.selectionSet.setStyle({ outlineColor: SELECTION_COLORS.VALID });

  const threekitElem = document.getElementById(elId);
  replaceLogoUrl(threekitElem).catch((err) => {
    console.log('Error encountered replacing logo url');
  });

  // TODO: Find a new fix
  // api.player.cameraController.setSyncTransform(true); // Necessary for switching btw cameras and returning to the same view

  return api;
}

async function replaceLogoUrl(parentElem) {
  const startTime = new Date();

  const check = () => {
    const logo = Array.from(
      parentElem.getElementsByTagName('a')
    ).filter((elem) => elem.className.includes('logo'))[0];
    if (!logo) {
      if (new Date() - startTime < 120000)
        return new Promise((resolve) => setTimeout(resolve, 50)).then(check);
    } else logo.href = 'https://www.threekit.com/lovesac';
  };
  return check();
}

/**
 *
 * @param {*} config
 * @param {*} initSceneConfig specifies if scene level configuration needs to be set (floor, global fabrics)
 * @param {*} saveData
 */
export async function initializeConfiguration(
  config,
  initSceneConfig,
  saveData = true
) {
  if (saveData) initialConfigurationData = JSON.parse(JSON.stringify(config)); // to be reused on reset

  if (initSceneConfig) {
    const tkConfig = buildTKSceneConfiguration(config);
    await setSceneConfiguration(tkConfig);
  }

  const { items, attachments } = config;

  await Promise.all(items.map(internalAddItem));
  const itemIds = Array.from(getItems().keys());

  // if we don't call this, new model nodes aren't evaluated yet so the camera's
  // framing function doesn't find anything so frames the whole scene
  await window.threekit.api.player.evaluateSceneGraph();

  frameScene(itemIds);

  // update attachment state
  // TODO: We already added functionality used by the move tool to scan
  // overlapping attachments and connect them. Could just use that on load, and
  // then don't even need to save/restore attachment state.
  if (attachments) {
    await Promise.all(
      attachments.map(({ itemA, itemB }) => {
        const { id: connectorAId } = getNamedConnectorForItem(
          itemA.itemId,
          itemA.connectorName
        );
        const { id: connectorBId } = getNamedConnectorForItem(
          itemB.itemId,
          itemB.connectorName
        );

        return attach(connectorAId, connectorBId, false);
      })
    );
  }

  await updateCapacity(items);
}

export async function initializeFloor() {
  return setFloor(DEFAULT_FLOOR_TYPE);
}

export async function reset(configurationId) {
  const allItemIds = Array.from(getItems().keys());
  await removeItems(allItemIds);

  // This is a workaround for a bug caused by the translator not having yet
  // cleared references to the deleted nodes. If we then reinit a configuration
  // with the same node ids, the translator ends up keeping its old references,
  // with item meshes appearing in the wrong place. This only seemed to happen
  // when switching between resetting one saved if with another (when both use
  // the same item ids).
  // Unclear why this wasn't also happening when just resetting the original
  // configuration after rearranging its items.
  window.threekit.api.player.translator.translateNodes();

  const configData = configurationId
    ? (await getSavedConfiguration(configurationId)).variant
    : initialConfigurationData;

  if (configData) await initializeConfiguration(configData, true, false);

  if (!configData || !configData.hasOwnProperty('floor'))
    await initializeFloor();
}
